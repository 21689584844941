import { createArray } from 'src/utils';

export const MERCHANTS = [
  {
    id: 0,
    imgLogo: require('./assets/logo-0.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
    label: 'A',
  },
  {
    id: 1,
    imgLogo: require('./assets/logo-1.png'),
    destinationURL: 'https://store.united-arrows.co.jp/',
    label: 'B',
  },
  {
    id: 2,
    imgLogo: require('./assets/logo-2.png'),
    destinationURL: 'http://www.urban-research.jp/',
    label: 'C',
  },
  {
    id: 3,
    imgLogo: require('./assets/logo-3.png'),
    destinationURL: 'https://yz-store.com',
    label: 'D',
  },
  {
    id: 4,
    imgLogo: require('./assets/logo-4.png'),
    destinationURL: 'https://www.ralphlauren.co.jp/',
    label: 'E',
  },
  {
    id: 5,
    imgLogo: require('./assets/logo-5.png'),
    destinationURL: 'https://hoshinoresorts.com/jp/',
    label: 'F',
  },
  {
    id: 6,
    imgLogo: require('./assets/logo-6.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
    label: 'G',
  },
  {
    id: 7,
    imgLogo: require('./assets/logo-7.png'),
    destinationURL:
      'https://www.airtrip.jp/?utm_source=airtrip.jp&utm_medium=web&utm_campaign=paidy_cashbackcampaign_from20250401',
    label: 'H',
  },
  {
    id: 8,
    imgLogo: require('./assets/logo-8.png'),
    destinationURL: 'https://www.dmm.com/',
    label: 'I',
  },
  {
    id: 9,
    imgLogo: require('./assets/logo-9.png'),
    destinationURL: 'https://www.dlsite.com/index.html',
    label: 'J',
  },
  {
    id: 10,
    imgLogo: require('./assets/logo-10.png'),
    destinationURL: 'https://shop.hololivepro.com/',
    label: 'K',
  },
  {
    id: 11,
    imgLogo: require('./assets/logo-11.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
    label: 'L',
  },
  {
    id: 12,
    imgLogo: require('./assets/logo-12.png'),
    destinationURL: 'https://www.samsung.com/jp/offer/',
    label: 'M',
  },
  {
    id: 13,
    imgLogo: require('./assets/logo-13.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
    label: 'N',
  },
  {
    id: 14,
    imgLogo: require('./assets/logo-14.png'),
    destinationURL: 'https://ec.geo-online.co.jp/shop/',
    label: 'O',
  },
  {
    id: 15,
    imgLogo: require('./assets/logo-15.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
    label: 'P',
  },
  {
    id: 16,
    imgLogo: require('./assets/logo-16.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
    label: 'Q',
  },
  {
    id: 17,
    imgLogo: require('./assets/logo-17.png'),
    destinationURL: 'https://mvno.geo-mobile.jp/',
    label: 'R',
  },
  {
    id: 18,
    imgLogo: require('./assets/logo-18.png'),
    destinationURL: 'https://www.buyma.com/lifestyle/?af=987',
    label: 'S',
  },
  {
    id: 19,
    imgLogo: require('./assets/logo-19.png'),
    destinationURL: 'https://ec.geo-online.co.jp/shop/',
    label: 'T',
  },
  {
    id: 20,
    imgLogo: require('./assets/logo-20.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
    label: 'U',
  },
  {
    id: 21,
    imgLogo: require('./assets/logo-21.png'),
    destinationURL: 'https://www.ralphlauren.co.jp/',
    label: 'V',
  },
  {
    id: 22,
    imgLogo: require('./assets/logo-22.png'),
    destinationURL: 'https://dwango-ticket.jp/',
    label: 'W',
  },
  {
    id: 23,
    imgLogo: require('./assets/logo-23.png'),
    destinationURL: 'https://www.dlsite.com/index.html',
    label: 'X',
  },
  {
    id: 24,
    imgLogo: require('./assets/logo-24.png'),
    destinationURL: 'https://shop.hololivepro.com/',
    label: 'Y',
  },
  {
    id: 25,
    imgLogo: require('./assets/logo-25.png'),
    destinationURL: 'https://www.samsung.com/jp/offer/',
    label: 'Z',
  },
  {
    id: 26,
    imgLogo: require('./assets/logo-26.png'),
    destinationURL: 'https://mvno.geo-mobile.jp/',
    label: 'Z',
  },
];

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchantIds: createArray(0, 4),
  },
  {
    categoryValue: 2,
    merchantIds: createArray(5, 7),
  },
  {
    categoryValue: 3,
    merchantIds: createArray(8, 11),
  },
  {
    categoryValue: 4,
    merchantIds: createArray(12, 17),
  },
  {
    categoryValue: 5,
    merchantIds: createArray(18, 21),
  },
  {
    categoryValue: 6,
    merchantIds: createArray(22, 26),
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: '航空券・新幹線・ホテル予約' },
  { value: 3, label: '動画・ライブ配信・ホビーグッズ' },
  { value: 4, label: '家電・デジタル機器' },
  { value: 5, label: '家具・インテリア雑貨' },
  { value: 6, label: 'その他' },
];

export const FIXED_MERCHANTS = [];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [];

export const FIXES_TAGS = [];
